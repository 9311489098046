
$sm: 400px;
$md: 1024px;
// $lg: 1024px;
$xl: 1200px;
$xxl: 1800px;

@mixin sm {
  @media screen and (max-width: ($sm)) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: ($md)) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: ($lg)) {
    @content;
  }
}

@mixin xl {
  @media screen and (max-width: ($xl)) {
    @content;
  }
}

@mixin xxl {
  @media screen and (max-width: ($xxl)) {
    @content;
  }
}

// Specific mixin for iPhone devices
@mixin iphone {
  @media screen and (max-width: 600px) and (min-device-width: 375px) and (-webkit-device-pixel-ratio: 2),
  screen and (max-width: 600px) and (min-device-width: 414px) and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}

// Specific width for iPhone X
@mixin iphoneSE {
  @media screen and (width: 375px) and (height: 667px) {
    @content;
  }
}

// Specific width for iPad
@mixin ipad {
  @media screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}
