@import "../../styles/responsive.scss";

@include md {
  .none-mob {
    display: none !important;
  }
  header {
    background-color: none !important;
    nav {
      position: absolute;
      width: 100% !important;
      background-color: white !important;
      top: 65px !important;
      // right: 25px;
      ul {
        // display: block !important;
        display: flex;
        flex-direction: column !important;
        z-index: 999 !important;
      }
    }
  }
  .menu-icon {
    display: block !important;
    z-index: 999;
    margin-left: auto !important;
  }
}
header {
  width: 100vw;
  height: auto;
  background-color: transparent;
  margin: auto;
  position: fixed;
  top: 0px;
  z-index: 100;
  transition: 0.8s;
  padding-right: 15px;
  // backdrop-filter: blur(5px);
  // opacity: 0.6;
  // border-bottom: 1px solid #ccc;
  .header-container {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    .logo {
      height: 55px;
      width: 170px;
      margin: 5px;
    }
    nav {
      margin-left: auto;
      ul {
        margin-bottom: 0px;
        li {
          display: inline-block;
          position: relative;
          a {
            text-decoration: none;
            color: #002e5e;
            padding: 8px 15px;
            display: flex;
            align-items: center;
            transition: all 0.2s;
            border-radius: 5px;
            cursor: pointer;
            i {
              font-size: 10px;
              margin-left: 5px;
            }
          }
          &:hover {
            a {
              background-color: #f1f1f1;
              // color: black;
            }
          }
          .submenu {
            position: absolute;
            top: 150%;
            left: 0%;
            background-color: #f1f1f1;
            z-index: 999;
            width: 150px;
            padding-left: 0px;
            transition: all 0.3s;
            visibility: hidden;
            opacity: 0;
            li {
              width: 100%;
              padding: 0px;
              span {
                color: black;
                transition: all 0.2s;
                padding: 10px 20px;
                font-size: 16px;
                width: 100%;
                background-color: white;
                border-bottom: 1px solid #f1f1f1;
                &:hover {
                  background-color: #ccc;
                }
              }
              a {
                color: black;
                transition: all 0.2s;
                padding: 10px 20px;
                font-size: 16px;
                width: 100%;
                background-color: white;
                border-bottom: 1px solid #f1f1f1;
                &:hover {
                  background-color: #ccc;
                }
              }
            }
          }

          &:hover {
            .submenu {
              top: 105%;
              visibility: visible;
              opacity: 1;
            }
          }
        }
        button {
          color: white;
          &:hover {
            color: #002e5e;
          }
        }
      }
    }
  }
}
header.sticky {
  background-color: white;
  transition: 0.5s;
  .header-container {
    nav {
      ul {
        li {
          a {
            color: #002e5e;
          }
        }
        button {
          color: white;
        }
      }
    }
  }
}
.btn-clr-dark {
  background-color: #002e5e;
  border: none;
  span {
    font-weight: 450;
    font-size: 18px;
  }
  &:hover {
    background-color: #fde21c;
  }
}
.menu-icon {
  display: none;
}
