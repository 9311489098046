@import "../../styles/responsive.scss";
@include md {
  .download-app-section {
    height: 350px !important;
    width: 360px !important;
    max-width: 95vw !important;
    background-image: url("https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/mobileBanner.webp") !important;

    padding: 20 !important;
    .button-container {
      left: 5px !important;
      right: 0px !important;
      bottom: -40px !important;
      top: 355px !important;
      a {
        margin: 2px !important;
        img {
          height: 35px !important;
        }
      }
    }
  }
}
.app-download-container {
  .download-app-section {
    border-radius: 12px;
    position: relative;
    // background-color: #002e5e;
    background-image: url("https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/BBKbanner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    // opacity: 0.91;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 15px;
    // min-width: 100vh !important;
    .button-container {
      // width: 100%;
      display: flex;
      gap: 6px;
      justify-content: center;
      position: absolute;
      left: 33px;
      bottom: 15px;
      a {
        margin: 10px;
        img {
          height: 50px;
          width: auto;
        }
      }
    }
    .download-image {
      display: flex;
      margin-top: 4rem;
      justify-content: center;
      align-items: center;
      img {
        width: 73%;
        opacity: 1;
        margin-left: 120px;
      }
    }
  }
  
}
