@import "../../styles/responsive.scss";

.location-modal {
  width: 100%;
  min-height: 10vh;
  .location-container {
    width: 100%;
    min-height: 10rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .city-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5px;
      cursor: pointer;
      width: 130px;
      height: 130px;
      border-radius: 65px;

      @include md {
        width: 115px !important;
      }
      .city-image {
        img {
          height: 80px;
          width: 85px;
        }
      }
    }
  }
  .all-city-dropdown {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      margin-bottom: 15px;
    }
    .city-form {
      width: 50%;
      .form-group {
        width: 100%;
        .form-label {
          width: 100%;
        }
        .form-select {
          width: 100%;
        }
        option {
          width: 100%;
        }
      }
    }
  }
}