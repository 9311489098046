@import "../../styles/responsive.scss";
@include md {
  .footer-container {
    flex-direction: column;
    gap: 10px !important;
    .footer-col {
      margin-left: 10px;
      min-width: 90vw !important;
      margin-top: 20px !important;
      .address {
        width: 90vw !important;
      }
    }
    .footer-left-col {
      flex-direction: column;
      width: 90vw !important;
      gap: 10px !important;
      overflow: hidden;
    }
  }
  .footer-right-col {
    width: 90vw !important;
    overflow: hidden !important;
  }
  .icon-conatiner {
    width: 80% !important;
  }
}
.loc-map {
  margin: 0;
}

footer {
  overflow: hidden;
}
.follow-us {
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: 50px;
  }
  h3 {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .icon-conatiner {
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
    a {
      i {
        color: #002e5a;
        &:hover {
          color: #fde21c;
        }
      }
    }
  }
}
.footer {
  min-height: 350px;
  width: 100vw;
  background-color: #002e5e;
  display: flex;
  justify-content: space-around;
  gap: 60px;
  .footer-container {
    min-height: 350px;
    // width: 100vw;
    background-color: #002e5e;
    display: flex;
    justify-content: space-between;
    gap: 60px;
  }
  .footer-left-col {
    display: flex;
    width: 65%;
    justify-content: space-between;
    gap: 60px;
  }
  .footer-right-col {
    width: 20%;
  }
  .footer-col {
    .icon-conatiner {
      // width: 60%;
      display: flex;
      gap: 10px;
      align-items: center;
      padding-bottom: 20px;
      a {
        i {
          color: #fff;
          &:hover {
            color: #fde21c;
          }
        }
      }
    }
    height: 80%;
    margin-top: 40px;
    h6 {
      color: white;
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 25px;
    }
    .link {
      text-decoration: none;
      display: block;
    }
    p {
      color: white;
      cursor: pointer;
      font-size: 15px;
      margin-bottom: 10px;
      a {
        color: #fde21c;
      }
    }
    .address {
      width: 300px;
    }
  }
}
.policy-container {
  text-align: center;
  padding-bottom: 10px;
  background-color: #fff;
  .policy {
    color: #002e5e;
    font-size: 14px;
    margin-top: 10px;
  }
}
.copyright {
  height: 50px;
  width: 100%;
  background-color: #fde21c;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: #002e5a;
    text-align: center;
  }
}
