.thank-you-page-container {
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  
  .container-body {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  