@import '@fortawesome/fontawesome-free/css/all.min.css';

html{
  min-width: 100vw !important;
  max-height: 100vh !important;
  /* overflow: hidden !important; */
}
body {
  margin: 0;
  background: #f8f8f8 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw !important;
}

code {
  width: 100vw !important;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 50%;
 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808080;
}
