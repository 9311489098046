@import "src/styles/responsive.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --font-size: 0.8em;
  --bg1: #f5f8f7;
  --blue: #3498db;
  --green: #2ecc71;
  --purple: #9b59b6;
  --gold: #f1c40f;
  --red: #e74c3c;
  --orange: #e67e22;
  --shadow1: 0 2px 4px #00000026, 0 3px 6px #0000001f;
  --shadow2: 0 2px 6px #00000044, 0 4px 7px #00000022;
}


@include md{
  h1{
    // margin-top: 100px !important;
    font-size: 25px !important;
    margin-bottom: 25px !important;
  }
  h5{
    // margin-top: 100px !important;
    font-size: 25px !important;
    // margin-bottom: 25px !important;
  }
  .mt-5{
    margin-top: 6rem !important;
  }
}
* {
  margin: 0;
  padding: 0;
  font-family: 'poppins',sans-serif;
  // width: 100% !important;

}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 3s forwards;

  @keyframes fadeInAnimation {
    to {
      opacity: 1;
    }
  }
  transition: all 0.5s ease-in-out;
}
.new-con{
  // background-color: rgba($color: #002e5e, $alpha: 1);
  background: url("https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/banner-ezgif.com-jpg-to-webp-converter.webp");
  background-repeat: no-repeat;
  background-size: cover;

}
.full-page-container{
  // background: rgba(0, 46, 94, 0.8);
 width: 100vw !important;
  min-height: 55rem;
  margin: 0;
  padding: 0;
overflow: hidden;
}
.not-logged-in{
  width: 100vw !important;
  min-height: 29rem;
  margin: 0;
  padding: 0;
}
.container{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.position-relative{
  // margin-bottom: 130px;
  position: relative;
  top: 60px;
}
.mt-5{
margin-top: 5rem;
}
h5 {
  
  margin-top: 50px;
  position: relative;
  padding: 0;
  color: #002e5e;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 300;
  text-align: center;
  text-transform: none;
  margin-bottom: 50px;
  font-family: 'poppins',sans-serif;

  &:before {
    content: '';
    width: 60px;
    height: 3px;
    background: #002e5e;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
  }

  span {
    // font-weight: 700;
    padding-bottom: 5px;
    color: #2f2f2f;
  }
}
h1 {
  
  margin-top: 50px;
  position: relative;
  padding: 0;
  color: #002e5e;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 34px;
  font-weight: 300;
  text-align: center;
  text-transform: none;
  margin-bottom: 50px;
  font-family: 'poppins',sans-serif;

  &:before {
    content: '';
    width: 60px;
    height: 3px;
    background: #002e5e;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
  }

  span {
    // font-weight: 700;
    padding-bottom: 5px;
    color: #2f2f2f;
  }
}
.btn-primary{
  background-color: #002e5e;
  color: #fff;
  --bs-btn-active-bg: #002e5e;
  border: none;
  
  &:hover{
    background-color: #fde21c;
    color: #002e5e;
 
  }
}
.btn:disabled{
  background-color: #002e5e;
  opacity: 0.7;
  border-color: #002e5e;
 
}

.flatpickr-months
{
.flatpickr-month {
  background: #002e5e !important;
}
}
.flatpickr-current-month 
{
.flatpickr-monthDropdown-months {
  background: #002e5e !important;

}
}
.flatpickr-weekdays{
  background: #002e5e !important;

}

.flatpickr-weekday{
  background: #002e5e !important;
  color: #fff !important;
  
}
.flatpickr-day.selected{
  border: #002e5e !important;
  background: #002e5e !important;
}