@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
@import "../../styles/responsive.scss";

@include md {
  .modal-content {
    // height: 80vh;
    min-height: 550px !important;
  }

  .none-bs {
    display: block !important;
  }

  .model-body {
    height: 60vh !important;
  }

  .overlay-container {
    display: none;
  }

  .faram {
    gap: 3px;

    .inp {
      background-color: #eee;
      border: none;
      padding: 12px 15px;
      margin: 4px 0 !important;
      width: 100%;
    }
  }

  .bttn {
    width: 88% !important;
    height: 50px;
    border-radius: 5px !important;
    margin-bottom: 10px !important;
  }
}

.model-body {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 75vh;
  border-radius: 55px;
  /* margin: -20px 0 50px; */
}

.heading-log {
  font-weight: bold;
  margin: 10px;
}

.otp-details {
  height: 50px;
}

.sp {
  font-size: 12px;
}

.none-bs {
  display: none;
}

.ahref {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.bttn {
  border-radius: 8px;
  border: 1px solid #193d55;
  background-color: #193d55;
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  margin-top: -4px;
  width: 50%;
  padding: 9px 25px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;

  &:hover {
    background-color: #fde21c;
    border: 1px solid #fde21c;
    color: #193d55;
  }
}

.img-log {
  height: 300px;
  width: 300px;
}

.bttn-log {
  border-radius: 8px;
  border: 1px solid #193d55;
  background-color: #fde21c;
  color: #193d55;
  font-size: 12px;
  font-weight: bold;
  padding: 9px 25px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;

  &:hover {
    background-color: #193d55;
    border: 1px solid white;
    color: white;
  }
}

.bttn:focus {
  outline: none;
}

.bttn.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.faram {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;

  .inp {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
  }
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;

  text-align: center;

  h2 {
    width: 40%;
    font-size: 13px;
    text-align: center;
    // border-bottom: 1px solid #a39999;
    line-height: 0.1em;
    margin: 10px 0 20px;

    span {
      background: #fff;
      color: #696464;
      padding: 0 10px;
    }
  }
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

@keyframes show {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
}

.overlay {
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #193d55, #004a8c);
  background: linear-gradient(to right, #002e5a, #002e5a);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-right {
  right: 0;
}

.overlay-left {
  left: 0;
  z-index: 999;
}

.social-container {
  margin: 20px 0;
  display: none;

  a {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 912px) {
  .sign-in-container {
    left: 0;
    width: 100%;
    z-index: 2;
  }

  .bttn {
    border-radius: 12px;
    border: 1px solid #193d55;
    background-color: #193d55;
    color: #ffffff;
    font-size: 10px;
    font-weight: bold;
    padding: 10px 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }

  .bttn-log {
    border-radius: 12px;
    border: 1px solid #193d55;
    background-color: #193d55;
    color: #ffffff;
    font-size: 8px;
    font-weight: bold;
    padding: 5px 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
}

.dimmed-button {
  opacity: 0.6;
  pointer-events: none;

  span {
    display: none;
  }
}

.resent-otp-container {
  color: #3f5bd9;
  margin-left: 190px;
  margin-top: -12px;
  margin-bottom: 8px;
  font-size: 15px;

  &:hover {
    cursor: pointer;
  }
}

.box {
  margin-left: -17px;
  padding: 0px;
  width: 100%;
  flex-wrap: nowrap;


  @include md {
    width: 120% !important;
    margin-left: 0.5px !important;
  }
  .input-error {
    border: 1px solid red;
  }

  .input {
    position: relative;
    width: 2.5em;
    height: 2.9em;
    border-radius: 14px;
    padding: 0;
    cursor: pointer;
    outline: none;
    background-color: rgb(235, 235, 235);
    box-shadow: inset 3px 3px 6px #d1d1d1, inset -3px -3px 6px #ffffff;
    top: 0.2em;
    left: 0.2em;
    margin: 10px 4px 20px 4px;
    transition: .4s ease-in-out;
    text-align: center;
    line-height: 0.5em;
    // border: 1px solid #014389;
   

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;

    }

    .input-error {
      border: 2px solid red;
    }

    &:hover {
      box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;

      &:focus {
        border-radius: 5px;
        background-color: #cee6ff;
        box-shadow: inset 0px 0px 0px #d1d1d1, inset 0px 0px 0px #ffffff;
        border: 1px solid #157fef;

      }
    }

  }

  // .otp-button {
  //   padding: 0.5em 1em;
  //   margin: 10px 4px 10px 4px;
  //   font-family: 'poppins', sans-serif;
  //   border: none;
  //   border-radius: 5px;
  //   letter-spacing: 2px;
  //   cursor: pointer;
  //   color: #ffffff;
  //   transition: all 1000ms;
  //   font-size: 15px;
  //   position: relative;
  //   overflow: hidden;
  //   background-color: #002e5e;

  //   &::before {
  //     position: absolute;
  //     left: -50px;
  //     top: 0;
  //     width: 0;
  //     height: 100%;
  //     background-color: #fde21c;
  //     transform: skewX(45deg);
  //     z-index: -1;
  //     transition: width 1000ms;
  //   }

  //   &:hover {
  //     color: #002e5e;
  //     transform: scale(1.1);
  //     box-shadow: 4px 5px 17px px #002e5e;

  //     &::before {
  //       width: 250%;
  //     }

  //   }


  // }
  .otp-button {
    width: 92px;
    height: 37px;
    padding: 0.5em 1em;
    margin: 10px 0px 10px -2px;
    font-family: 'poppins', sans-serif;
    border: none;
    border-radius: 5px;
    letter-spacing: 2px;
    cursor: pointer;
    color: #ffffff;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    background-color: #002e5e;
  }

  .otp-button::before {
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }

  .otp-button:hover {
    color: #ffffff;
    transform: scale(1.1);
  }

  .otp-button:hover::before {
    width: 250%;
  }

  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 0.4rem !important;
  }
}
